import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import operationSignatures from '../_constants/operationSignatures.json';
import { PageRendererComponent, prePageCall } from '@marriott/mi-headless-utils';
import { DataLayerWrapper, NEXT_PUBLIC_ENV_KEYS } from '@marriott/mi-groups-components';
import { brandSpecificUrls } from '../renderer.config';

function PageRenderer(props) {
  const router = useRouter();
  return (
    <DataLayerWrapper {...props}>
      <PageRendererComponent {...props} key={router.asPath} />
    </DataLayerWrapper>
  );
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const groupsSpecificData = async (request: any) => {
  return {
    operationSignatures,
    nextPublicEnvKeys: NEXT_PUBLIC_ENV_KEYS,
    selector: brandSpecificUrls.includes(request.query.page.join('/')) ? request.query.brandCode : null,
  };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps: GetServerSideProps<any> = prePageCall(groupsSpecificData);
export default PageRenderer;
