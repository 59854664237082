import React from 'react';
import Head from 'next/head';
import { getDataLayerScript } from '@marriott/mi-headless-utils';

export const DataLayerWrapper: React.FC<{
  children: JSX.Element;
  dataLayer: Record<string, string | number | boolean>;
}> = props => {
  return (
    <>
      <Head>
        <script
          id="miDataLayer"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dangerouslySetInnerHTML={{ __html: getDataLayerScript(props?.dataLayer as unknown as any) }}
        ></script>
      </Head>
      {props.children}
    </>
  );
};
